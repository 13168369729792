@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  scroll-behavior: smooth;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  font-family: Inter, serif!important;
  font-size: 14px;
  line-height: 19px;
  scroll-behavior: smooth;
}

button, input, textarea {
  font-family: Inter, serif;
}

textarea {
  resize: none;
}

button:active, button:focus, input:focus {
  outline: none;
}
a{
  color: #1565C0!important;
}
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background: none;
  border: none;
  outline: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body, html {
  height: 100%;
  background: #FAFAFA;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
}

.font-size-medium{
  font-size: 17px;
}
.block-row-column{
  display: block;
}

.row-column{
  display: flex;
}

.reverse-row-column{
  display: flex;
}
.column-row{
  display: flex!important;
  flex-direction: column!important;
}
.g-1{
  gap: 10px;
}
.menuitem {
  font-weight: 300;
  font-size: 0.75rem;
}

.caption {
  opacity: .8;
  font-weight: 500;
  font-size: 0.9375rem;
}

.link {
  font-weight: 500;
  font-size: 0.875rem;
}

.linksub {
  font-weight: 600;
  font-size: 0.875rem;
}

.small {
  font-weight: 500;
  font-size: 0.75rem;
}

.smallest {
  font-weight: 300;
  font-size: 0.625rem;
}

.category {
  letter-spacing: 0.17px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.625rem;
}

/* color */
.red {
  color: #E63333;
}

.blue {
  color: #1170B4;
}

.black {
  color: #151515;
}

.gray {
  color: rgba(21, 21, 21, 0.5);
}

.secondary-gray {
  color: #949494;
}

.green {
  color: #019875;
}

.orange {
  color: #ED9F08;
}

.dark_blue {
  color: #083759;
}

.dark_gray {
  color: rgba(0, 0, 0, 0.5);
}

.bg_red {
  background: #E63333;
}

.bg_blue {
  background: #1170B4;
}

.bg_black {
  background: #151515;
}

.bg_gray {
  background: rgba(21, 21, 21, 0.5);
}

.bg_green {
  background: #019875;
}

.bg_orange {
  background: #ED9F08;
}

.bg_dark_blue {
  background: #083759;
}

.text-underline {
  text-decoration: underline !important;
}

/* margin */
.m-center {
  margin: 0 auto
}

.main {
  padding-top: 80px;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
}
.my-container{
  margin-inline: 75px!important;
}
/* Siderbar overlay */
.sidebar-overlay {
  height: 100%;
  width: 464px;
  position: fixed;
  right: 0;
  top: 80px;
  z-index: 1;
  transform: translate(500px);
  background: #F7F8FC;
  transition: all .3s;
  overflow: hidden;
  opacity: 0;
}

.sidebar-overlay.show {
  transform: translate(0);
  opacity: 1;
}

/* Additional margin */
.mt-32 {
  margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.mx-32 {
  margin-right: 32px;
  margin-left: 32px;
}

.my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

/* dot */
.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

/* pointer */
.pointer {
  cursor: pointer;
}

/* divider */
.divider {
  border-bottom: 1px solid #EEE;
  margin: 30px 0;
}
.divider-without-margin {
  border-bottom: 1px solid #EEE;
}
.icon-white{
  fill: #FFFFFF;
}
.cursor-pointer {
  cursor: pointer
}

.btn-primary.disabled,
.btn-primary:disabled{
  border-radius: 6px;
  background: #E6E8EB;
  color: #808080;
}
.btn-cancel{
  color: #1565C0;
}
.btn-white {
  background-color: #F5F5F5;
  color: #083759;
  height: 50px;
  padding: 0 40px;
}
.btn-bordered {
  width: auto;
  padding: 0 10px;
  line-height: 27px;
  height: 30px;
  position: relative;
  font-size: 12px;
  text-align: center;
  border: 1.5px solid white;
  border-radius: 32px;
  min-width: 123px;
  color: #fff;
}
.btn-remove{
  border-radius: 4px;
  background: #FFE2E2;
  color: #ED3838!important;
}
.btn-add{
  border-radius: 4px;
  background: #BFEED7;
  color: #3AB479!important;
}
input:focus {
  outline:none !important;
}
select:focus {
  outline:none !important;
  box-shadow: none!important;
}
.state-for-btn {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  min-width: 129px;
  font-family: Inter, serif;
  /*font-size: 12px;*/
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  height: fit-content;
}
.btn-duplicate{
  border-radius: 4px;
  background: #69AAF3;
  color: white!important;
}
.btn-duplicate p{
  color: white!important;
}
.btn-remove p {
  color: #ED3838!important;
}
/* Loading */
.form-control.is-loading {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url('assets/icons/spinner.gif') !important;
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-mandatory {
  padding-right: calc(1.5em + 0.75rem)!important;
  background-image: url('assets/icons/icon-mandatory.svg') !important;
  background-repeat: no-repeat!important;
  background-position: center right calc(0.375em + 0.1875rem)!important;
  background-size: initial;
}
.form-control.is-hide{
  display: none;
}
.form-control.is-valid{
  background-image: url('assets/icons/icon-valid.svg') !important;
  background-repeat: no-repeat!important;
  background-position: center right calc(0.375em + 0.1875rem)!important;
  background-size: initial;
}
.form-control.is-invalid{
  border: #ED3838 solid 1px!important;
  color: #ED3838!important;
}
.form-control:disabled{
  background: #FAFAFA;
  color: #808080;
  font-weight: 700;
  font-size: 14px;
}
.form-control {
  padding: 10px 15px 10px 15px!important;
  border-radius: 6px!important;
  border: 1px solid #E6E8EB!important;
  height: max-content;
  color: #AAAAAA;
}
.page-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
}

.page-subtitle {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.58);
  margin-bottom: 25px;
}
.page-flex-my{
  margin-top: 100px;
  display: flex;
}
/*MODAL*/
.modal-content{
  border-radius: 15px!important;
}
.modal-dialog {
  max-width: 700px;
}
/*NEW*/
h1{
  color: #484848;
  font-size: 32px;
  font-family: Inter, serif!important;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
}
h2{
  color: #484848;
  font-size: 24px;
  font-family: Inter, serif!important;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
}
h3{
  color: #484848;
  font-size: 18px;
  font-family: Inter, serif!important;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}
.body1{
  color: #484848;
  font-size: 18px;
  font-family: Inter, serif!important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
.body2{
  color: #484848;
  font-size: 14px;
  font-family: Inter, serif!important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.body3{
  color: #484848;
  font-family: Inter, serif!important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.body4{
  color: #484848;
  font-family: Inter, serif!important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.body5{
  color: #484848;
  font-size: 10px;
  font-style: normal;
  font-family: Inter, serif!important;
  font-weight: 500;
  line-height: 12px; /* 120% */
}
.color-medium-gray{
  color: #808080!important;
}
.color-regular-gray {
  color: #AAAAAA!important;
}
.color-red{
  color:#ED3838!important;
}
.color-black{
  color:#1F1F1F!important;
}
.gap-30{
  gap:30px;
}
.mt-20{
  margin-top: 20px;
}
.mb-20{
  margin-bottom: 20px;
}
.btn{
  display: inline-flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #484848;
  font-family: Inter, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}
.btn-request{
  padding: 4px 15px!important;
  min-width: 129px;
}
.btn:focus {
  outline: none!important;
  box-shadow: none!important;
  border: none!important;
}
.btn-confirm{
  border-radius: 6px;
  background: #3AB479;
  color: white;
}
.btn-confirm:hover{
  border-radius: 6px;
  background: #8bdcb3;
  color: white;
}
.btn-reject{
  border-radius: 6px;
  background: #ED3838;
  color: white;
}
.btn-reject:hover{
  border-radius: 6px;
  background: #ef6666;
  color: white;
}
.btn-primary{
  border-radius: 6px;
  background: #1565C0;
  border: none;
  color: white;
}
.btn-primary:hover{
  border-radius: 6px;
  background: #69AAF3;
  border: none;
}
.btn-request-modal{
  height: 100%;
  background: #1565C0;
  border-radius: 0px 6px 6px 0px;
  color: white;
}
.btn-request-modal:hover{
  height: 100%;
  background: #69AAF3;
  border-radius: 0px 6px 6px 0px;
  color: white;
}
.btn-outline{
  border-radius: 6px;
  border: 1px solid #1565C0;
  background: #FFF;
  color: #1565C0;
  text-align: center;
}
.btn-outline:hover{
  background: #69AAF3;
  border: 1px solid #69AAF3;
  color: #FFFFFF;
  text-align: center;
}
.btn-with-icon{
  border-radius: 6px;
  background: #69AAF3;
  color: white;
}
.btn-with-icon:hover{
  border-radius: 6px;
  background: #92bdef;
  color: white;
}
.btn-gray{
  border-radius: 6px;
  background: #E6E8EB;
  color: #808080;
}
.btn-gray:hover{
  border-radius: 6px;
  background: #c0c2c9;
}
.white-bg {
  background-color: white;
  width:100%;
}

.gray-bg {
  border-top: 1px solid #EEE;
  border-bottom: 1px solid #EEE;
  background:  #FAFAFA;
  width:100%;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #1565C0!important;
  outline: 0;
  box-shadow: none!important;
}
/*mat*/
.mat-mdc-tab-header{
  border-radius: 10px!important;
  border: 1px solid #EEE!important;
  background:  #FAFAFA!important;
  padding: 5px!important;
  max-height: 40px!important;
  align-items: center!important;
}
.mat-mdc-tab-labels{
  align-items: center!important;
}
.mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination{
  box-shadow: none;
}
.mat-mdc-tab.mdc-tab--active {
  border: none;
  border-radius: 5px;
  background: #1565C0;
  color: white!important;
  height: 30px!important;
}
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: white!important;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  font-family: "Inter", sans-serif;
}
.mat-mdc-tab .mdc-tab__text-label{
  letter-spacing: 0;
  font-family: "Inter", sans-serif;
}
.mdc-tab-indicator{
  display: none!important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
  color: white!important;
}
.mat-mdc-form-field{
  font-family: "Inter", sans-serif;
}

.mat-mdc-form-field-focus-overlay {
     background-color: transparent;
  color: white!important;

}
.mat-mdc-tab .mdc-tab__text-label:active {
  border: none;
  border-radius: 5px;
  background: #1565C0;
  color: white!important;
  padding:7px  21px;
  max-height: 30px;
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent;
}
.mat-option{
  color: #808080;
  font-weight: 500;
}
.mat-mdc-tab-body-wrapper{
  margin-inline: -20px;
}
.mat-option.mat-active{
  color: #1565C0;
  background: initial;
}

.mat-form-field-wrapper{
  padding:0!important;
}
.mat-form-field-infix{
  padding:0!important;
  border:none!important;
}
.mat-form-field-appearance-standard .mat-form-field-flex{
  padding: 0!important;
}
.mat-form-field-appearance-standard .mat-form-field-underline{
  display: none!important;
}
.mat-form-field-prefix, .mat-form-field-suffix{
  display: flex;
  align-items: center;
}
.mat-form-field-flex{
  gap: 10px;
  align-items: center;
}
.cdk-overlay-dark-backdrop{
  backdrop-filter: blur(20px)
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface{
  background-color: rgb(191, 238, 215)!important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none!important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__label{
  text-align: center;
  color: rgb(58, 180, 121)!important;
}
.reload img {
  width: 20px;
}

.reload {
  float: right;
}
/*common styles*/
.contract-list-page {
  display: flex;
  padding: 30px 30px 30px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border-radius: 15px;
  border: 1px solid #EEE;
  background: #FFF;
  width:100%;
  margin-top: 30px;
}
.contract-list-page .contract-list-header .title{
  display: flex;
  flex-direction: column;
  gap:5px;
}
.contract-list-header{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}
.redesign-card {
  display: flex;
  padding: 15px 30px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  text-align: left;
}
.info-side{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  width: 100%;
}
.redesign-card .info-side .bottom{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
}
.info-bottom {
  display: flex;
  align-items: center;
  gap: 30px;
}
.redesign-list-group{
  border-radius: 10px;
  border: 1px solid #EEE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}
.input-form .mdc-text-field{
  border-radius: 6px 0 0 6px!important;
}
.input-form .mdc-text-field--filled{
  border-radius: 6px 0 0 6px!important;
}

.mdc-text-field{
  padding: 10px 15px 10px 15px !important;
  border-radius: 6px !important;
  border: 1px solid #E6E8EB !important;
  height: max-content;
  color: #AAAAAA;
  background: #FFFFFF!important;
}

.mat-mdc-form-field.is-mandatory .mdc-text-field{
  padding-right: calc(1.5em + 0.75rem)!important;
  background-image: url('assets/icons/icon-mandatory.svg') !important;
  background-repeat: no-repeat!important;
  background-position: center right calc(0.375em + 0.1875rem)!important;
  background-size: initial;
}
.mat-mdc-form-field.is-valid .mdc-text-field{
  padding-right: calc(1.5em + 0.75rem)!important;
  background-image: url('assets/icons/icon-valid.svg') !important;
  background-repeat: no-repeat!important;
  background-position: center right calc(0.375em + 0.1875rem)!important;
  background-size: initial;
}
.always-open-below .mat-select-panel {
  transform-origin: top !important;
  top: 100% !important; /* Ensure it always opens downward */
}
.mat-mdc-form-field-infix{
  min-height: max-content;
  padding: 0!important;
}
.mdc-text-field--filled{
  --mdc-filled-text-field-active-indicator-height: 0!important;
  --mdc-filled-text-field-focus-active-indicator-height: 0!important;
  --mdc-filled-text-field-container-shape: 0!important;
}

.mat-select .mat-select-arrow-wrapper .mat-select-arrow{
  background-image: url('assets/icons/icon-select-arrow.svg') !important;
  border: none;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}
.mat-mdc-select-arrow{
  background-image: url('assets/icons/icon-select-arrow.svg') !important;
  border: none!important;
  width: 16px!important;
  height: 16px!important;
  background-size: contain!important;
  background-repeat: no-repeat!important;
}
.mat-mdc-select-arrow svg{
  display:none
}
.mat-select .mat-select-arrow-wrapper .mat-select-arrow.is-mandatory{
  background-image: url('assets/icons/icon-close-dup.svg') !important;
  border: none;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}
.mat-select .mat-select-arrow-wrapper .mat-select-arrow.is-valid{
  background-image: url('assets/icons/icon-valid.svg') !important;
  border: none;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}
.mat-select-panel {
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 256px;
  border-radius: 6px!important;
  border: #1565C0 solid 1px;
  box-shadow: none !important;
}
.mat-mdc-form-field-subscript-wrapper{
  display: none!important;
}
.mat-mdc-select-panel{
  border: #1565C0 solid 1px;
  border-radius: 0 0 6px 6px !important;
  box-shadow: none!important;
  background-color: #FAFAFA!important;
}
.mdc-text-field--focused{
  border: #1565C0 solid 1px!important;
  border-radius: 6px 6px 0 0!important;
  box-shadow: none!important;
  background-color: #FFFFFF!important;
}
.mat-mdc-option .mdc-list-item__primary-text{
  color: rgb(128, 128, 128);
  font-family: Inter!important;
  font-size: 15px!important;
  font-weight: 500!important;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
  color: #1565C0 !important;
}
.mat-mdc-form-field-focus-overlay{
  background: none;
}

/*.mat-select-panel::-webkit-scrollbar{*/
/*  width: 0;*/
/*}*/
/*.mat-select-panel::-webkit-scrollbar-track {*/
/*  background: transparent;*/
/*}*/
/*.mat-select-panel::-webkit-scrollbar-thumb {*/
/*  background: transparent;*/
/*}*/

.mat-select-panel::-webkit-scrollbar-button {
  display: none;
}
.mat-select-panel::-webkit-scrollbar {
  width: 5px;
}

.mat-select-panel::-webkit-scrollbar-track {
  background: transparent;
  padding: 5px;
}

.mat-select-panel::-webkit-scrollbar-thumb {
  background: #E6E8EB;
  border-radius: 1.5px;
}
/*.cdk-overlay-pane{*/
/*  background: #FFFFFF!important;*/
/*}*/
.ngx-ptr-loadingbar-container{
  top:80px!important;
}

.mdc-dialog__surface{
  background: none;
  border: none;
  box-shadow: none;
}

/*MOBILE*/
@media (max-width: 768px) {
  .mat-mdc-dialog-actions{
    flex-direction: column;
  }
  .block-row-column{
    display: flex;
    flex-direction: column;
  }
  .row-column{
    flex-direction: column;
  }
  .reverse-row-column{
    flex-direction: column-reverse;
  }
  .column-row {
    flex-direction: row!important;
    align-items: center!important;
    flex-wrap: wrap;
  }
  .row-landing {
    flex-direction: column;
    gap: 18px;
    margin-top: 20px;
  }
  .border-card{
    width: 100%;
  }
  h1{
    font-size: 32px;
  }
  h2{

  }
  .body1{
    font-size: 14px;
  }
  .mat-tab-label{
    /*width: 100%!important;*/
    flex: 0 0 auto;
    max-width: calc(100% / 3)!important;
  }
  .mat-tab-body-wrapper{
    margin-inline:-20px!important;
  }
  /*common*/
  .contract-list-page{
    border:none;
    margin:0;
    padding: 0;
    gap:15px;
  }

  .my-container{
    margin-inline: 20px!important;
  }
  .contract-list-header{
    /*display: flex;*/
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap:15px;
  }
  .redesign-card{
    padding:15px 20px;
  }
  .info-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
  body{
    background: #FFFFFF;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .border-card{
    width: 47%;
  }
}
@media (min-width: 1400px){
  .my-container{
    margin-inline: 150px!important;
  }
}


html, body { height: 100%; }

